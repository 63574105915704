/**
 * Created by osirvent on 01/08/2016.
 */
angular.module('annexaApp')
    .directive('annexaCustomField',['$compile', '$filter', 'Language', function ($compile, $filter, Language) {
        var getTemplate = function(scope) {
            var template = '';
            template += '<div class="box">';
            template += '   <div class="box-header p-t-0 p-r-0">';
            template += '     <h2 translate="global.literals.customFields">NEW Custom fields</h2>';
            template += '   </div>';
            template += '   <div class="p-t-sm sortable-container clearfix redraw-floating" sv-root sv-part="customFieldsDefinition.customFieldsProcedure" sv-on-sort="customFieldsDefinition.sort($item, $partFrom, $partTo, $indexFrom, $indexTo)">';
            template += '       <div class="col-xs-12 col-sm-12 m-b-xs bg-faded" data-ng-repeat="tramCustomField in customFieldsDefinition.customFieldsProcedure | orderBy:\'viewOrder\'" sv-element>';
            template += '         <div class="row  p-t-sm">';
            template += '           <div class="col-xs-5 col-lg-4 p-r-0" sv-handle>';
            template += '               <i class="p-t-sm m-t-xs m-r-sm fa fa-bars grey-pencil" aria-hidden="true"></i>';
            template += '               <label class="label-strong p-y-sm m-b-xs">{{tramCustomField.customField[customFieldsDefinition.languageColumn]}}</label>';
            template += '           </div>';
            template += '           <div class="col-xs-6 col-lg-5 col-xl-6 p-r-0">';
            template += '               <ui-select fix-focus-on-touch ng-model="tramCustomField.requiredString" theme="bootstrap" ng-change="customFieldsDefinition.updateFunction(tramCustomField)">';
            template += '                   <ui-select-match >{{$select.selected.description | translate}}</ui-select-match>';
            template += '                   <ui-select-choices repeat="item.id as item in customFieldsDefinition.customFieldRequiredList | filter: $select.search" >';
            template += '                       <div ng-bind-html="item.description | translate"></div>';
            template += '                   </ui-select-choices>';
            template += '               </ui-select>';
            template += '           </div>';
            template += '           <div class="col-xs-2 col-lg-3 col-xl-2">';
            template += '               <p class="m-b-0 pull-right p-r-xs m-t-sm">';
            template += '                   <a href="" ng-hide="disableElement(tramCustomField.customField)" ng-click="customFieldsDefinition.editModalFunction($index)" class="remove-button "><i class="fa fa-fw fa-pencil grey-pencil" title="{{\'global.literals.edit\' | translate}}"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span></a>';
            template += '                   <a href="" ng-show="disableElement(tramCustomField.customField)" ng-click="customFieldsDefinition.editModalFunction($index)" class="remove-button "><i class="fa fa-fw fa-eye grey-pencil" title="{{\'global.literals.see\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.see\' | translate}}</span></a>';
            template += '                   <a href="" ng-click="customFieldsDefinition.removeFunction($index)" class="remove-button m-t-xs"><i class="fa fa-fw fa-remove grey-pencil" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></a>';
            template += '               </p>';
            template += '           </div>';
            template += '         </div>';
            template += '         <div class="row p-t-xs">';
            template += '           <div class="col-xs-4">';
            template += '               <label class="label-strong small p-y-sm m-b-xs">Valor per defecte</label>'
            template += '           </div>';
            template += '           <div class="col-xs-8 flexboxData outLabel">';
            template += '               <annexa-custom-field-component ';
            template += '                   key="{{tramCustomField.customField.id}}" ';
            template += '                   frontend-type="{{tramCustomField.customField.frontendType}}" ';
            template += '                   backend-type="{{tramCustomField.customField.backendType}}" ';
            template += '                   label="" ';
            template += '                   model="tramCustomField.value" ';
            template += '                   min-length="tramCustomField.customField.minLength" ';
            template += '                   max-length="tramCustomField.customField.maxLength" ';
            template += '                   decimal-positions="tramCustomField.customField.decimalPositions" ';
            template += '                   required="false" ';
            template += '                   option-label-prop="{{customFieldsDefinition.languageColumn}}" ';
            template += '                   option-value-prop="id" ';
            template += '                   options="tramCustomField.customField.listValues" ';
            template += '                   options-inline="false"></annexa-custom-field-component>';
            template += '           </div>';
            template += '         </div>';
            template += '       </div>';


            /*TODO PROVES AMB DESPLEGABLE*/
            template += '   <div class="col-sm-12 col-xs-12 bg-faded b-2x b-all  p-y-sm m-b-xs">';
            template += '       <div class="row" >';
            template += '           <div class="col-xs-4 col-sm-4 " >';
            template += '               <i class=" p-t-xs m-r-sm fa fa-bars grey-pencil" aria-hidden="true"></i>';
            template += '               <label class="label-strong   m-b-0">Aspecte</label>';
            template += '           </div>';
            template += '           <div class="col-xs-7 col-sm-7 ">';
            template += '               <p class="m-b-0  " >Nom del grup</p>';
            template += '           </div>';
            template += '           <div class="col-xs-1 col-sm-1" data-toggle="collapse" data-target="#collapseBlock" role="button" aria-expanded="false" aria-controls="collapseBlock">';
            template += '               <span class="fa fa-sort-down grey-pencil" aria-hidden="true"></span>';
            template += '           </div>';
            template += '       </div>'; /* /row collapse toggle*/

            template += '       <fieldset class="collapse  b-a-0 p-a-0"  id="collapseBlock">';
            template += '           <div class=" row m-t-sm">';
            template += '               <div class="col-xs-5 col-lg-4 p-r-0   p-t-sm">';
            template += '                   <label class="label-strong p-y-sm m-b-xs">Definició propietat</label>';
            template += '               </div>';
            template += '               <div class="col-xs-5 col-lg-5 col-xl-6  p-t-sm">';
            template += '                   <select class="form-control"><option value="Opcional">Opcional</option><option value="Requerit">Requerit</option><option value="No editable">No editable</option></select>';
            template += '               </div>';
            template += '               <div class="col-xs-2 col-lg-3 col-xl-2  p-t-sm">';
            template += '                   <p class="m-b-0 pull-right p-r-xs m-t-sm">';
            template += '                       <a href="" class="remove-button "><i class="fa fa-fw fa-pencil grey-pencil" title="{{\'global.literals.edit\' | translate}}"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span></a>';
            template += '                       <a href="" class="remove-button m-t-xs"><i class="fa fa-fw fa-remove grey-pencil" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></a>';
            template += '                   </p>';
            template += '               </div>';
            template += '           </div>';
            template += '           <div class="row p-t-xs">';
            template += '               <div class="col-xs-4">';
            template += '                   <label class="label-strong small p-y-sm m-b-xs">Valor per defecte</label>'
            template += '               </div>';
            template += '               <div class="col-xs-8 flexboxData outLabel">';
            template += '                   <input type="text" class="form-control" />';
            template += '               </div>';
            template += '           </div>';
            template += '           <div class=" row m-t-sm">';
            template += '               <div class="col-xs-5 col-lg-4 p-r-0   p-t-sm">';
            template += '                   <label class="label-strong p-y-sm m-b-xs">Definició propietat</label>';
            template += '               </div>';
            template += '               <div class="col-xs-5 col-lg-5 col-xl-6  p-t-sm">';
            template += '                   <select class="form-control"><option value="Opcional">Opcional</option><option value="Requerit">Requerit</option><option value="No editable">No editable</option></select>';
            template += '               </div>';
            template += '               <div class="col-xs-2 col-lg-3 col-xl-2  p-t-sm">';
            template += '                   <p class="m-b-0 pull-right p-r-xs m-t-sm">';
            template += '                       <a href="" class="remove-button "><i class="fa fa-fw fa-pencil grey-pencil" title="{{\'global.literals.edit\' | translate}}"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span></a>';
            template += '                       <a href="" class="remove-button m-t-xs"><i class="fa fa-fw fa-remove grey-pencil" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></a>';
            template += '                   </p>';
            template += '               </div>';
            template += '           </div>';
            template += '           <div class="row p-t-xs">';
            template += '               <div class="col-xs-4">';
            template += '                   <label class="label-strong small p-y-sm m-b-xs">Valor per defecte</label>'
            template += '               </div>';
            template += '               <div class="col-xs-8 flexboxData outLabel">';
            template += '                   <input type="text" class="form-control" />';
            template += '               </div>';
            template += '           </div>';
            template += '       </fieldset>';
            template += '   </div>';


            template += '   </div>';

            template += '   <div class="clearfix m-x padding">';
            template += '       <div class="col-xs-12 p-t-sm b-t">';
            template += '           <span class="m-b-xs small label-strong" translate="global.literals.addField">Add field</span>';
            template += '       </div>';
            template += '       <div class="col-xs-12 ">';
            template += '           <ui-select fix-focus-on-touch ng-model="newCustomField" theme="bootstrap" on-select="customFieldsDefinition.createModalFunction($model)">';
            template += '               <ui-select-match >{{$select.selected[customFieldsDefinition.languageColumn]}}</ui-select-match>';
            template += '               <ui-select-choices repeat="item in customFieldsDefinition.customFields | filter: $select.search" >';
            template += '                   <div ng-bind-html="item[customFieldsDefinition.languageColumn]"></div>';
            template += '               </ui-select-choices>';
            template += '           </ui-select>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope:{
                customFieldsDefinition: '='
            },
            transclude: true,
            link: function(scope, element, attr, ctrl, transclude) {
                scope.disableElement = function(cField){
                    if(!cField.disableElement || cField.frontendType == 'SELECT' || cField.frontendType == 'MULTIPLESELECT' || cField.frontendType == 'CHECKBOX' || cField.frontendType == 'RADIO'){
                        return false;
                    }  else{
                        return true;
                    }
                };

                scope.$on('annexaCustomFieldComponentModelChanged', function (event, args) {
                    var indexCustomField = $linq(scope.customFieldsDefinition.customFieldsProcedure).indexOf("x => x.customField.id == " + args.key);

                    if(indexCustomField != -1) {
                        if(args.model && args.model.hasOwnProperty('id')) {
                            scope.customFieldsDefinition.customFieldsProcedure[indexCustomField].value = args.model.id;
                        } else if(args.model) {
                            scope.customFieldsDefinition.customFieldsProcedure[indexCustomField].value = args.model;
                        }

                        if(args.model) {
                            scope.customFieldsDefinition.updateFunction(scope.customFieldsDefinition.customFieldsProcedure[indexCustomField]);
                        }
                    }
                });

                element.html(getTemplate(scope.customFieldsDefinition)).show();
                $compile(element.contents())(scope);
            }
        }

    }])
    .directive('annexaEditCustomField',['$compile', '$filter', 'Language','$rootScope', 'CommonAdminModals', 'AnnexaModalFactory', 'globalModals', 'AnnexaEntityFactory', 'AnnexaFormlyFactory', '$http', 'CommonService', function ($compile, $filter, Language, $rootScope, CommonAdminModals, AnnexaModalFactory, globalModals, AnnexaEntityFactory, AnnexaFormlyFactory, $http, CommonService) {
        var getTemplate = function(scope, htmlPermissions) {
            var template = '';

            template += '<div class=" b-a-0 m-b-sm" ng-if="!field.invisible" ng-repeat="field in customFields | orderBy: \'viewOrder\'">';
            template += '   <div class="">';
            template += '       <label class="_700 text m-b-0" ng-if="!field[nameLanguageColumn]">{{field.customField[languageColumn] || \'\'}} <span ng-if="field.required" class="text-danger" title="{{ \'global.INVALID_REQUIRED\' | translate}}">*</span><span ng-if="field.requiredForEndDossier" class="text-danger" title="{{ \'global.literals.REQUIRED_TO_END\' | translate}}">**</span><span ng-if="field.noEditable" class="text-danger" title="{{ \'global.literals.NO_EDITABLE\' | translate}}">***</span></label>';
            template += '		<label class="_700 text m-b-0" ng-if="field[nameLanguageColumn]">{{field[nameLanguageColumn] || \'\'}} <span ng-if="field.required" class="text-danger" title="{{ \'global.INVALID_REQUIRED\' | translate}}">*</span><span ng-if="field.requiredForEndDossier" class="text-danger" title="{{ \'global.literals.REQUIRED_TO_END\' | translate}}">**</span><span ng-if="field.noEditable" class="text-danger" title="{{ \'global.literals.NO_EDITABLE\' | translate}}">***</span></label>';
            template += '   </div>';
            template += '   <div class="" ng-if ="field[descriptionLanguageColumn] && field[$ctrl.descriptionLanguageColumn] != \'\'">';
            template += '       <label class="m-b-0 cursiva-grey-small cursiva-grey">{{field[descriptionLanguageColumn] || \'\'}}</label>';
            template += '   </div>';
            template += '   <div class="" ng-if ="!field[descriptionLanguageColumn] && field.customField[$ctrl.descriptionLanguageColumn] && field.customField[$ctrl.descriptionLanguageColumn] != \'\'">';
            template += '       <label class="m-b-0 cursiva-grey-small cursiva-grey">{{field.customField[descriptionLanguageColumn] || \'\'}}</label>';
            template += '   </div>';
            template += '   <div class="pos-relative">';
            template += '       <p class="label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'INPUT\' && field.customField.backendType == \'DATETIME\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{\'editable-click\' : isUpdate}" ng-show="!inputDate1Form.$visible" editable-date="field.valueFromJSON" e-form="inputDate1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.customField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" ng-click="inputDate1Form.$show()" class="abs-r-0 abs-b-0 abs-b-0" ng-show="!inputDate1Form.$visible && isUpdate  && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs " ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'INPUT\' && field.customField.backendType == \'INTEGER\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{\'editable-click\' : isUpdate}" ng-show="!input1Form.$visible" editable-number="field.valueFromJSON" e-form="input1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.customField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0"  ng-click="input1Form.$show()" ng-show="!input1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'INPUT\' && field.customField.backendType == \'DECIMAL\'">';
            template += '           <a class="annexa-field p-l-xs "  ng-class="{\'editable-click\' : isUpdate}" ng-show="!input1Form.$visible" editable-number="field.valueFromJSON" e-step="{{field.step}}" e-form="input1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.customField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="input1Form.$show()" ng-show="!input1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil "  title="{{\'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'INPUT\' && field.customField.backendType == \'ADDRESS\'">';
            template += '           <a class="annexa-field p-l-xs "  ng-class="{\'editable-click\' : isUpdate}" ng-show="!input1Form.$visible" e-step="{{field.step}}" e-form="input1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.customField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
		    template += '           <a href="" ng-click="openModalAddress(field)" role="button" aria-label="{{\'global.literals.edit\' | translate}}" class="abs-r-0 abs-b-0" ng-show="!input1Form.$visible && isUpdate && !field.noEditable" ' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil "  title="{{\'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'INPUT\'  && field.customField.backendType != \'DATETIME\' && field.customField.backendType != \'INTEGER\' && field.customField.backendType != \'DECIMAL\'  && field.customField.backendType != \'ADDRESS\'">';
            template += '           <a class="annexa-field p-l-xs "  ng-class="{\'editable-click\' : isUpdate}" ng-click="openWindow(input1Form, $data, isUpdate, field.noEditable)" ng-show="!input1Form.$visible" editable-text="field.valueFromJSON" e-form="input1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.customField.templateTag}}" e-maxlength="900">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="input1Form.$show()" ng-show="!input1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs minH-20 pre-line" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'TEXTAREA\'" >';
            template += '           <a class="annexa-field p-l-xs " ng-class="{\'editable-click\' : isUpdate}" ng-click="openWindow(textArea1Form, $data, isUpdate, field.noEditable)" ng-show="!textArea1Form.$visible" editable-textarea="field.valueFromJSON" e-maxlength="900" e-rows="3" e-form="textArea1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.customField.templateTag}}">';
            template += '               {{ field.valueFromJSON }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="textArea1Form.$show()" ng-show="!textArea1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs minH-20" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'JSON\'" >';
            template += '           <a href="" class="abs-r-0 abs-b-0" style="min-height: 2.6em;" ng-click="openJSONViewer(field.valueFromJSON, field.customField[languageColumn])" ng-show="!json1Form.$visible" role="button" aria-label="{{\'global.literals.open\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-expand grey-pencil" title="{{\'global.literals.open\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.open\' | translate}}</span>';
            template += '           </a>';
            template += '           <a class="annexa-field p-l-xs " ng-class="{\'editable-click\' : isUpdate}" ng-click="openWindow(json1Form, $data, isUpdate, field.noEditable)" ng-show="!json1Form.$visible" editable-textarea="field.valueFromJSON" e-maxlength="9999" e-rows="3" e-form="json1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.customField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="json1Form.$show()" ng-show="!json1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs " ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'SELECT\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{ \'editable-click\' : isUpdate }" ng-show="!select1Form.$visible" editable-select="field.valueFromJSON" e-ng-options="item.value as item[languageColumn] for item in field.customField.listValues" e-form="select1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.customField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }} ';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="select1Form.$show()" ng-show="!select1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil "  title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs " ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'SELECT_LINKED\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{ \'editable-click\' : isUpdate }" ng-show="!select1Form.$visible" editable-select="field.valueFromJSON" e-ng-options="item.value as item[languageColumn] for item in getOptions(field)" e-form="select1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.customField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }} ';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="select1Form.$show()" ng-show="!select1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil "  title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'MULTIPLESELECT\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{ \'editable-click\' : isUpdate }" ng-show="!multiselect1Form.$visible" editable-select="field.valueFromJSON" e-multiple e-ng-options="item.value as item[languageColumn] for item in field.customField.listValues"  e-form="multiselect1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.customField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="multiselect1Form.$show()" ng-show="!multiselect1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil "  title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="radio-list label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'CHECKBOX\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{ \'editable-click\' : isUpdate }" ng-show="!checkBox1Form.$visible" editable-select="field.valueFromJSON" e-multiple e-ng-options="item.value as item[languageColumn] for item in field.customField.listValues" e-form="checkBox1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.customField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="checkBox1Form.$show()" ng-show="!checkBox1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="radio-list label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'RADIO\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{ \'editable-click\' : isUpdate }" ng-show="!radio1Form.$visible"  editable-radiolist="field.valueFromJSON" e-ng-options="item.value as item[languageColumn] for item in field.customField.listValues" e-form="radio1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.customField.templateTag}}">';
            template += '               {{getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="radio1Form.$show()" ng-show="!radio1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="radio-list label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'STRUCTURAL_SELECT\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{ \'editable-click\' : isUpdate }" ng-show="!tree1Form.$visible"  editable-select-tree="field.valueFromJSON" e-form="tree1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required">';
            template += '               {{getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="tree1Form.$show()" ng-show="!tree1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="radio-list label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'CF_GROUP\'">';
            template +='			<annexa-object-custom-fields-edit-group custom-fields="customFields" custom-field="field" data="groupData" class="row m-xs-0"></annexa-object-custom-fields-edit-group>';
            template += '       </p>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        return {
            restrinct: 'E',
            replace: true,
            scope: {
                customFields: '=',
                languageColumn: '=',
                isUpdate: '=',
                updateFunction: '=',
                permissions: '=',
                profile: '=',
                parentId: '=?',
                objectParent: '=?'
            },
            link: function(scope, element, attr) {
                scope.htmlPermissions = '';
                scope.groupData = { 
        			extra:{
        				isEdit:scope.isUpdate,
        				seeSelectedLinked:true,
						valuesFromLinkedCustomFields:{parentId:((scope.parentId)?scope.parentId:undefined), objectParent:scope.objectParent, inConfig:false}
        			}
        		}
                if(scope.permissions && scope.profile && scope.profile.length > 0) {
                    scope.htmlPermissions += ' annexa-permission annexa-permission-only="permissions" annexa-permission-only-profile="profile" ';
                } else if (scope.permissions) {
                	scope.htmlPermissions += ' annexa-permission annexa-permission-only="permissions"';
                } else if (scope.profile && scope.profile.length > 0) {
                	scope.htmlPermissions += ' annexa-permission annexa-permission-only-profile="profile" ';
                }

                angular.forEach(scope.customFields,function (value, key) {
                    if(value.value){
                        var object = undefined;
                        try{
	                    	object = angular.fromJson(value.value.replace(/(?:\r\n|\r|\n)/g, '<br/>'));
	                        if(object.value && object.value.replace) {
	                            value.valueFromJSON = object.value.replace(/<br\s*\/?>/gi, '\n');
	                        }else {
	                            value.valueFromJSON = object.value;
	                        }
	                        if(value.customField.backendType == "DATETIME"){
	                            if(value.valueFromJSON){
	                            	value.valueFromJSON = new Date(value.valueFromJSON);
	                            }
	                        }
	                        if(value.customField.frontendType === 'STRUCTURAL_SELECT'){
	                        	var selected = undefined;
	                        	var options = angular.copy(value.customField.listValues); 
	                        	_.forEach(options, function(val){
	                        		if(val.parentValue && val.parentValue.id){
	                        			val.parent = {id:val.parentValue.id};
	                        		}
	                        	});
	                        	options = CommonService.getTreeData(options, Language.getActiveColumn());
	                            var found = false;
	                            if(options){
		                            angular.forEach(options, function (val, key) {
		                                 if (!found) {
		                                     selected = $linq(val).singleOrDefault(undefined, "x => x.id == " + value.valueFromJSON);
		                                     if (selected) {
		                                         found = true;
		                                     }
		                                 }
		                            });
	                            }
	                        	value.valueFromJSON = {
	                    			model: ((selected)?{$selected: selected}:{}),
	                                options: options,
	                                required: value.required
	                        	}
	                        }
                        } catch (error) {
                            if(value.customField.frontendType == 'MULTIPLESELECT' || value.customField.frontendType == 'CHECKBOX'){
                                value.valueFromJSON = [];
                            }else if(value.customField.frontendType === 'STRUCTURAL_SELECT'){
                            	var options = angular.copy(value.customField.listValues); 
                            	_.forEach(options, function(val){
                            		if(val.parentValue && val.parentValue.id){
                            			val.parent = {id:val.parentValue.id};
                            		}
                            	});
                            	options = CommonService.getTreeData(options, Language.getActiveColumn());
                                value.valueFromJSON = {
                        			model: {},
                                    options: options,
                                    required: value.required
                            	}
                            }else{
                                value.valueFromJSON = '';
                            }
                        }
                    }else{
                        if(value.customField.frontendType == 'MULTIPLESELECT' || value.customField.frontendType == 'CHECKBOX'){
                            value.valueFromJSON = [];
                        }else if(value.customField.frontendType === 'STRUCTURAL_SELECT'){
                        	var options = angular.copy(value.customField.listValues); 
                        	_.forEach(options, function(val){
                        		if(val.parentValue && val.parentValue.id){
                        			val.parent = {id:val.parentValue.id};
                        		}
                        	});
                        	options = CommonService.getTreeData(options, Language.getActiveColumn());
                            value.valueFromJSON = {
                    			model: {},
                                options: options,
                                required: value.required
                        	}
                        }else{
                            value.valueFromJSON = '';
                        }
                    }
                    if (value && value.customField && value.customField.backendType == "DECIMAL"){
                        if(value.customField.decimalPositions) {
                            value.step = '0.' + Array(value.customField.decimalPositions).join('0') + '1';
                        }else{
                            value.step = '0.01'
                        }
                    }
                    if(value && value.customField.frontendType === 'SELECT'){
                    	if(!value.required){
                    		if(!$linq(value.customField.listValues).firstOrDefault(undefined, "x => x.id == -1")){
                    			value.customField.listValues.unshift({id:-1, language1:$filter('translate')('global.literals.notAssign'), language2:$filter('translate')('global.literals.notAssign'), language3:$filter('translate')('global.literals.notAssign')});
                    		}
                    	}
                    }
                });
                scope.descriptionLanguageColumn = "descriptionLanguage" + Language.getActiveColumn().substr(Language.getActiveColumn().length - 1);
                scope.nameLanguageColumn = "nameLanguage" + Language.getActiveColumn().substr(Language.getActiveColumn().length - 1);
                scope.getCustomFieldValue = function(field){
                    var valueLiteral = '';
                    if(field.customField.frontendType == 'MULTIPLESELECT' || field.customField.frontendType == 'CHECKBOX'){
                        angular.forEach(field.valueFromJSON,function (valueJ, keyJ) {
                            angular.forEach(field.customField.listValues, function (value, key) {
                                if (value.value == valueJ) {
                                    if(valueLiteral) {
                                        valueLiteral = valueLiteral +', '+ value[scope.languageColumn];
                                    }else{
                                        valueLiteral = value[scope.languageColumn];
                                    }
                                }
                            });
                        });
                    }else if(field.customField.frontendType == 'SELECT' || field.customField.frontendType == 'SELECT_LINKED' || field.customField.frontendType == 'RADIO'){
                        angular.forEach(field.customField.listValues, function (value, key) {
                            if (value.value == field.valueFromJSON) {
                                valueLiteral = value[scope.languageColumn];
                            }
                        });
                    }else if(field.customField.frontendType == 'INPUT'){
                        if(field.customField.backendType == "DATETIME") {
                        	if(field.valueFromJSON){
	                            valueLiteral = $filter('date')(field.valueFromJSON, 'dd/MM/yyyy');
                        	}
                        }else if(field.customField.backendType == "DECIMAL"){
                            if(field.valueFromJSON){
                                valueLiteral = field.valueFromJSON;
                                var valueLiteral_parts = (""+valueLiteral).split('.');
                                if(valueLiteral_parts && valueLiteral_parts.length > 0){
                                    if(valueLiteral_parts[1]){
                                        if(field.customField && field.customField.decimalPositions){
                                            valueLiteral =  parseFloat(valueLiteral_parts[0]).toLocaleString() + parseFloat("0."+valueLiteral_parts[1]).toFixed(field.customField.decimalPositions).replace('0.',',');
                                        }else{
                                            valueLiteral =  parseFloat(valueLiteral_parts[0]).toLocaleString() + parseFloat("0."+valueLiteral_parts[1]).toFixed(2).replace('0.',',');
                                        }
                                    }else{
                                        if(field.customField && field.customField.decimalPositions){
                                            valueLiteral =  parseFloat(valueLiteral_parts[0]).toLocaleString() +  parseFloat("0.00").toFixed(field.customField.decimalPositions).replace('0.',',');
                                        }else {
                                            valueLiteral =  parseFloat(valueLiteral_parts[0]).toLocaleString() + ",00";
                                        }
                                    }
                                }
                            }else{
                                valueLiteral = field.valueFromJSON;
                            }
                        }else if(field.customField.backendType == "INTEGER"){
                            if(field.valueFromJSON){
                                valueLiteral = field.valueFromJSON.toLocaleString();
                            }else{
                                valueLiteral = field.valueFromJSON;
                            }
                        } else if (field.customField.backendType == "ADDRESS"){
                        	if (field.valueFromJSON) {
                                valueLiteral = AnnexaEntityFactory.postalAddressRender(field.valueFromJSON);
                            } else {
                                valueLiteral = field.valueFromJSON;
                            }
                        }else{
                            valueLiteral = field.valueFromJSON;
                        }
                    }else if(field.customField.frontendType == 'JSON' || field.customField.frontendType == 'CF_GROUP'){
                    	var maxLengthReadModeCustomFieldJson = 100;
                    	if ($rootScope.app.configuration.max_length_read_mode_custom_field_json && $rootScope.app.configuration.max_length_read_mode_custom_field_json.value > 0) {
                    		maxLengthReadModeCustomFieldJson = $rootScope.app.configuration.max_length_read_mode_custom_field_json.value;
                        }
                        if(field.valueFromJSON && field.valueFromJSON.length > maxLengthReadModeCustomFieldJson){
                            valueLiteral = field.valueFromJSON.substr(0,maxLengthReadModeCustomFieldJson) + '...';
                        }else{
                            valueLiteral = field.valueFromJSON;
                        }
                    }else if(field.customField.frontendType == 'STRUCTURAL_SELECT'){
                    	if(field && field.customField && field.customField.listValues){
	                    	angular.forEach(field.customField.listValues, function (value, key) {
	                            if (field.valueFromJSON && field.valueFromJSON.model && field.valueFromJSON.model.$selected && field.valueFromJSON.model.$selected.id && field.valueFromJSON.model && value.value == field.valueFromJSON.model.$selected.id) {
	                                valueLiteral = value[scope.languageColumn];
	                            }
	                        });
                    	}
                    }
                    return valueLiteral;
                };

                scope.updateCustomField = function(field, data, isLinked){
                    if(!field.required || (field.required && data)) {
                        var fieldValue = undefined;
                        if(field.value){
                            fieldValue = angular.fromJson(field.value.replace(/(?:\r\n|\r|\n)/g, '<br/>'));
                        }
                        if(fieldValue == null || fieldValue == undefined){
                            fieldValue = {id:'cf_'+field.customField.id,value:'' };
                        }
                        if(data && data instanceof Date) {
                            fieldValue.value = new Date(Date.UTC(data.getFullYear(),data.getMonth(),data.getDate(),00,00,00));
                        }else if(field && field.customField && field.customField.frontendType  === 'STRUCTURAL_SELECT'){
                        	if(data && data.model && data.model.$selected && data.model.$selected.id){
                        		fieldValue.value = data.model.$selected.id;
                        	}else{
                        		fieldValue.value = undefined;
                        	}
                        }else {
                            fieldValue.value = data;
                        }
                        
                        if (fieldValue.value && (field.customField.frontendType == 'JSON' || field.customField.frontendType == 'CF_GROUP')) {
                        	try {
                        		JSON.parse(fieldValue.value);
                            } catch (error) {
                    			return $filter('translate')("global.validation.json");
                    		}
                        }
                        field.value = angular.toJson(fieldValue);
                        if(!isLinked && field.relatedCustomFields && field.relatedCustomFields.length > 0){
                        	field.valuesRelatedCustomFields = {};
                        	field.jsonValuesRelatedCustomFields = {};
                        	var okRelated = true;
                        	var lvalue = undefined;
                        	if(field && field.customField && (field.customField.backendType === 'MULTIPLESELECT' || field.customField.backendType === 'CHECKBOX')){
                        		if(fieldValue && fieldValue.value && fieldValue.value.length != 1){
                        			okRelated = false;
                        		}
                        		if(okRelated){
                        			if(field.customField && field.customField.listValues){
                        				lvalue = $linq(field.customField.listValues).firstOrDefault(undefined, "x=> x.id == "+fieldValue.value[0]);
                        			}
                        		}
                        	}else{
                        		if(okRelated){
                        			if(field.customField && field.customField.listValues){
                        				lvalue = $linq(field.customField.listValues).firstOrDefault(undefined, "x=> x.id == "+fieldValue.value);
                        			}
                        		}
                        	}
                        	if(lvalue){
                        		_.forEach(field.relatedCustomFields, function(rc){
                        			if(rc.customFieldTemplateTag){
                        				var cffieldUpdated = $linq(scope.customFields).firstOrDefault(undefined, "x => x.customField && x.customField.templateTag == '"+rc.customFieldTemplateTag+"'");
                        				if(cffieldUpdated){
                        					if(lvalue[rc.customFieldTemplateTag]){
                        						
                        						var relatedFieldValue = undefined;
                        						if(cffieldUpdated.value){
                        							relatedFieldValue = angular.fromJson(cffieldUpdated.value.replace(/(?:\r\n|\r|\n)/g, '<br/>'));
                        						}
                        						if(relatedFieldValue == null || relatedFieldValue == undefined){
                        							relatedFieldValue = {id:'cf_'+cffieldUpdated.customField.id,value:'' };
                        						}
                        						try{
                        							if(cffieldUpdated.customField.backendType === 'DATETIME'){
                        								try{
                        									var dateParts = lvalue[cffieldUpdated.customField.templateTag].split("/");
                        									var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
                        									if(dateObject != "Invalid Date") {
                        										relatedFieldValue.value = dateObject;
                        									} else {
                        										dateParts = HelperService.dateMaskToDate(dateParts);
                        										relatedFieldValue.value = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
                        									}
                        								}catch(e){
                        									var dateTime = Date.parse(lvalue[cffieldUpdated.customField.templateTag]);
                        									if(dateTime){
                        										relatedFieldValue.value = new Date(dateTime);
                        									}else{
                        										relatedFieldValue.value = undefined;
                        									}
                        								}
                        							}else if(cffieldUpdated.customField.frontendType == 'INPUT' && cffieldUpdated.customField.backendType === 'INTEGER'){
                        								relatedFieldValue.value = parseInt(lvalue[cffieldUpdated.customField.templateTag]);
                        							}else if(cffieldUpdated.customField.frontendType == 'INPUT' && cffieldUpdated.customField.backendType === 'DECIMAL'){
                        								var valueLiteral_parts = (lvalue[cffieldUpdated.customField.templateTag]).split('.');
                        								if(valueLiteral_parts && valueLiteral_parts.length > 0){
                        									if(valueLiteral_parts[1]){
                        										if(cffieldUpdated.customField.decimalPositions){
                        											relatedFieldValue.value =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(cffieldUpdated.customField.decimalPositions));
                        										}else{
                        											relatedFieldValue.value =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
                        										}
                        									}else{
                        										if(cffieldUpdated.customField.decimalPositions){
                        											relatedFieldValue.value =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0.00").toFixed(cffieldUpdated.customField.decimalPositions));
                        										}else {
                        											relatedFieldValue.value =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
                        										}
                        									}
                        								}
                        							}else if(cffieldUpdated.customField.frontendType == 'INPUT' && cffieldUpdated.customField.backendType === 'ADDRESS'){
                        								//TODO no pot arribar un address des de BBDD
                        							}else if((cffieldUpdated.customField.frontendType === 'SELECT' || cffieldUpdated.customField.frontendType === 'SELECT_LINKED'  || cffieldUpdated.customField.frontendType === 'RADIO')){
                        								relatedFieldValue.value = parseInt(lvalue[cffieldUpdated.customField.templateTag]);
                        							} else if(cffieldUpdated.customField.frontendType === 'MULTIPLESELECT' || cffieldUpdated.customField.frontendType === 'CHECKBOX'){
                        								relatedFieldValue.value = [parseInt(lvalue[cffieldUpdated.customField.templateTag])];
                        							} else if(cffieldUpdated.customField.frontendType === 'STRUCTURAL_SELECT'){
                        								var aux = parseInt(lvalue[cffieldUpdated.customField.templateTag]);
                        								if(aux){
                        									var lvaux = $linq(cffieldUpdated.customField.listValues).firstOrDefault(undefined, "x => x.id == aux");
                        									if(lvaux){
                        										relatedFieldValue.value = lvaux.id;											
                        									}
                        								}
                        							}else{
                        								relatedFieldValue.value = lvalue[cffieldUpdated.customField.templateTag];
                        							}
                        						}catch(e){
                        							if(cffieldUpdated && cffieldUpdated.customField && (cffieldUpdated.customField.backendType === 'MULTIPLESELECT' || cffieldUpdated.customField.backendType === 'CHECKBOX')){
                        								relatedFieldValue.value = [];
                        							}else{
                        								relatedFieldValue.value = undefined;
                        							}
                        						}
                        					}else{
                        						if(cffieldUpdated && cffieldUpdated.customField && (cffieldUpdated.customField.backendType === 'MULTIPLESELECT' || cffieldUpdated.customField.backendType === 'CHECKBOX')){
                        							relatedFieldValue.value = [];
                        						}else{
                        							relatedFieldValue.value = undefined;
                        						}
                        					}
                        					field.valuesRelatedCustomFields[cffieldUpdated.customField.templateTag] = relatedFieldValue.value;
                        					field.jsonValuesRelatedCustomFields[cffieldUpdated.customField.templateTag] = angular.toJson(relatedFieldValue);
                        				}
                        			}
                        		});
                        	}
                        }
                        var callbackFunctionTaskType = function(){
                        	scope.$broadcast('customFieldSelectSelected', { customField: field.customField.id, selectedValue: field.id });
                        }
                        if(scope.updateFunction){
                            scope.updateFunction(field, callbackFunctionTaskType);
                        }else{
							if(field.customField && field.customField.backendType == 'ADDRESS' && field.value){
								var aux = undefined;
								try{
        							aux = angular.fromJson(field.value);
								}catch(e){
									console.error(e);
								}
			                    if(aux && aux.value){
									field.valueFromJSON = aux.value;
								}else {
									field.valueFromJSON = '';
								}
							}else{
								field.valueFromJSON = '';
							}
						}
                        if(!scope.updateFunction){
	                        if(field.relatedCustomField.frontendType == 'SELECT') {
	                        	scope.$broadcast('customFieldSelectSelected', { customField: field.customField.id, selectedValue: field.id });
	                        }
                        }
                    }else{
                        return 'required';
                    }
                };

                scope.getOptions = function(field) {
                    if(field.customField.frontendType == 'SELECT_LINKED'){
                    	var options = [];
                    	if(field.customField.fromQuery && field.customField.query && field.customField.query.id) {
                    		options = field.customField.listValues;
                    	}else{
	
	                        var parentField = $linq(scope.customFields).singleOrDefault(undefined, "x => x.customField.id == " + field.customField.linkedCustomField.id);
	
	                        if(parentField) {
	                            var parentValue = $linq(parentField.customField.listValues).singleOrDefault(undefined, "x => x.value == " + parentField.valueFromJSON);
	
	                            if(parentValue) {
	                                options = $linq(field.customField.listValues).where("x => x.parentValue.id == " + parentValue.id).toArray();
	                            }
	                        }
                    	}
                        return options;
                    }
                }

                scope.$on('customFieldSelectSelected', function (event, args) {
                    if(args.customField && args.selectedValue) {
                        var linkedFields = $linq(scope.customFields).where("x => x.customField.linkedCustomField && x.customField.linkedCustomField.id == " + args.customField).toArray();
                        _.forEach(linkedFields, function(field) {
                        	scope.updateCustomField(field, '', true);
                        	field.valueFromJSON = '';
                        	if(field && field.customField.fromQuery && field.customField.query && field.customField.query.id){
                        		if(args.customField && args.selectedValue && scope.parentId && scope.objectParent && field.identifierField && field.labelPropField && args.selectedValue) {
                        			var url = "";
                        			if(scope.objectParent === "DOSSIER"){
                        				url = './api/tram/new/dossier/calculateCustomFields/'+scope.parentId+'/'+field.customField.id+'/'+args.selectedValue+'/'+args.customField;
                        			}else if(scope.objectParent === "DOSSIER_TRANSACTION"){
                        				url = './api/tram/new/dossierTransaction/calculateCustomFields/'+scope.parentId+'/'+field.customField.id+'/'+args.selectedValue+'/'+args.customField;
                        			}else if(scope.objectParent === "TASK"){
                        				url = './api/tasks/calculateCustomFields/'+scope.parentId+'/'+field.customField.id+'/'+args.selectedValue+'/'+args.customField;
                        			}else if(scope.objectParent === "DOCUMENT"){
                        				url = './api/doc/calculateCustomFields/'+scope.parentId+'/'+field.customField.id+'/'+args.selectedValue+'/'+args.customField;
                        			}
                        			if(url && url !== ""){
                        				$http({method: 'GET',url: url}).then(function(data){
	                            		  if(data){
	                            			  var opts = ((data && data.data && data.data.length > 0)?JSOG.decode(data.data):[]);
	                            			  var newopts = [];
	                            			  _.forEach(opts, function(lv){
	                            					if(lv[field.identifierField]){
	                            						var label = '';
	                            						var labelPropFieldS = field.labelPropField.split("--@--@--");
	                            						_.forEach(labelPropFieldS, function(lp){
	                            							label = label + ((label)?((field.labelFieldsSeparator)?field.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
	                            						});
	                            						newopts.push({id: lv[field.identifierField], language1:label, language2:label, language3:label, value:lv[field.identifierField] });
	                            					}
	                            				});
	                            		  }
	                            		  field.customField.listValues = newopts;
                        				}).catch(function(error){
                        					field.customField.listValues = [];
                        				});
                        				
                        			 } else {
                                         field.customField.listValues = [];
                                     }
                                } else {
                                	field.customField.listValues = [];
                                }
                        	}
                        });
                    }
                });
                
                scope.openJSONViewer = function(modelValue, name) {
        			var modal = angular.copy(CommonAdminModals.jsonViewer);
        			modal.data = modelValue;
        			modal.title = name;
	                AnnexaModalFactory.showModal('modalJsonViewer', modal);
                }
                
                scope.openWindow = function(element, modelValue, isUpdate, noEditable){
                	if(scope.canOpenWindow(modelValue)){
                		var modelValueString = ((modelValue && scope.languageColumn && modelValue[scope.languageColumn])?modelValue[scope.languageColumn]:modelValue);
                		if(modelValueString){
                			window.open(modelValueString);
                		}else if(isUpdate && !noEditable && !element.$visible){
                			element.$show();
                		}
                	}else if(isUpdate && !noEditable && !element.$visible){
                		element.$show();
                	}
                }
                
                scope.canOpenWindow = function(modelValue){
            		var exist = false;
                	if(modelValue){
                		var startUrls = ['http://','https://'];
                		if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.url_editable_inputs && $rootScope.app.configuration.url_editable_inputs.urls && $rootScope.app.configuration.url_editable_inputs.urls.length > 0){
                			_.forEach($rootScope.app.configuration.url_editable_inputs.urls, function(item){
                				startUrls.push(item);
                			});
                		}
                		var modelValueString = ((modelValue && scope.languageColumn && modelValue[scope.languageColumn])?modelValue[scope.languageColumn]:modelValue);
                		if(modelValueString){
	                		_.forEach(startUrls, function(item){
	                			if(modelValueString.startsWith(item.toLowerCase())){
	                				exist = true;
	                			}
	                		});
                		}
                	}
                	return exist;
                }

				scope.openModalAddress = function (field){
                	var modal = angular.copy(globalModals.editAddressCF);
                	
                	modal.field = field;
                	modal.updateField = scope.updateCustomField;
                	
                	var defaultCountry = $rootScope.app.configuration.default_country;
                	if(defaultCountry && defaultCountry.value){
                        defaultCountry = defaultCountry.value;
                    }else{
                        defaultCountry = '';
                    }
                	
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {};
                    if(field.valueFromJSON && field.valueFromJSON !== ""){
                    	modal.annexaFormly.model.modal_body = field.valueFromJSON;
                    }
                    if(!modal.annexaFormly.model.modal_body.country && defaultCountry){
                        modal.annexaFormly.model.modal_body.country = {id:defaultCountry};
                    }
                    
                    modal.annexaFormly.model.modal_body.hasStates = true;
                    modal.annexaFormly.model.modal_body.hasCities = true;
                    modal.annexaFormly.model.modal_body.hasStreetTypes = true;
                    
                    modal.extra = this;
                    
                    
                    var submitFunction = function() { 
                    	var self = this;
                    	
                    	var address = {};
                        var addressAux = this.annexaFormly.model.modal_body;

                        address.country = addressAux.country;
                        if(addressAux.hasStates) {
                            if (addressAux.validState && addressAux.validState.id) {
                            	address.validState = addressAux.validState;
                            }
                        }else{
                        	address.stateAddress = addressAux.stateAddress;
                        }
                        if(addressAux.hasCities){
                            if(addressAux.validCity && addressAux.validCity.id) {
                            	address.validCity = addressAux.validCity;
                            }
                        }else{
                        	address.city = addressAux.city;
                        }
                        if(addressAux.hasStreetTypes && addressAux.streetType) {
                        	address.streetType = addressAux.streetType;
                        }
                        
                        address.street = addressAux.street;
                        address.isValidNumber = false;
                        address.numberAddres = addressAux.numberAddres;
                        address.floor = addressAux.floor;
                        address.block = addressAux.block;
                        address.door = addressAux.door;
                        address.stair = addressAux.stair;
                        address.postalCode = addressAux.postalCode;
                        
                        this.updateField(this.field, address);
                    	
                    	self.close();
                    }
                    
                    AnnexaFormlyFactory.showModal("modalNewAddress", modal, submitFunction, false);
                };
                element.html(getTemplate(scope.customFieldsDefinition, scope.htmlPermissions)).show();
                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('annexaEditCustomFieldGroup',['$compile', '$filter', 'Language','$rootScope', 'CommonAdminModals', 'AnnexaModalFactory', 'globalModals', 'AnnexaEntityFactory', 'AnnexaFormlyFactory', '$http', 'CommonService', function ($compile, $filter, Language, $rootScope, CommonAdminModals, AnnexaModalFactory, globalModals, AnnexaEntityFactory, AnnexaFormlyFactory, $http, CommonService) {
        var getTemplate = function(scope, htmlPermissions) {
            var template = '';

            template += '<div class=" b-a-0 m-b-sm">';
            template += '   <div class="pos-relative">';
            template += '       <p class="label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.relatedCustomField.frontendType == \'INPUT\' && field.relatedCustomField.backendType == \'DATETIME\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{\'editable-click\' : isUpdate}" ng-show="!inputDate1Form.$visible" editable-date="field.valueFromJSON" e-form="inputDate1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.relatedCustomField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" ng-click="inputDate1Form.$show()" class="abs-r-0 abs-b-0 abs-b-0" ng-show="!inputDate1Form.$visible && isUpdate  && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs " ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.relatedCustomField.frontendType == \'INPUT\' && field.relatedCustomField.backendType == \'INTEGER\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{\'editable-click\' : isUpdate}" ng-show="!input1Form.$visible" editable-number="field.valueFromJSON" e-form="input1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.relatedCustomField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0"  ng-click="input1Form.$show()" ng-show="!input1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.relatedCustomField.frontendType == \'INPUT\' && field.relatedCustomField.backendType == \'DECIMAL\'">';
            template += '           <a class="annexa-field p-l-xs "  ng-class="{\'editable-click\' : isUpdate}" ng-show="!input1Form.$visible" editable-number="field.valueFromJSON" e-step="{{field.step}}" e-form="input1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.relatedCustomField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="input1Form.$show()" ng-show="!input1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil "  title="{{\'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.relatedCustomField.frontendType == \'INPUT\' && field.relatedCustomField.backendType == \'ADDRESS\'">';
            template += '           <a class="annexa-field p-l-xs "  ng-class="{\'editable-click\' : isUpdate}" ng-show="!input1Form.$visible" e-step="{{field.step}}" e-form="input1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.relatedCustomField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
		    template += '           <a href="" ng-click="openModalAddress(field)" role="button" aria-label="{{\'global.literals.edit\' | translate}}" class="abs-r-0 abs-b-0" ng-show="!input1Form.$visible && isUpdate && !field.noEditable" ' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil "  title="{{\'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.relatedCustomField.frontendType == \'INPUT\'  && field.relatedCustomField.backendType != \'DATETIME\' && field.relatedCustomField.backendType != \'INTEGER\' && field.relatedCustomField.backendType != \'DECIMAL\'  && field.relatedCustomField.backendType != \'ADDRESS\'">';
            template += '           <a class="annexa-field p-l-xs "  ng-class="{\'editable-click\' : isUpdate}" ng-click="openWindow(input1Form, $data, isUpdate, field.noEditable)" ng-show="!input1Form.$visible" editable-text="field.valueFromJSON" e-form="input1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.relatedCustomField.templateTag}}" e-maxlength="900">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="input1Form.$show()" ng-show="!input1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs minH-20 pre-line" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.relatedCustomField.frontendType == \'TEXTAREA\'" >';
            template += '           <a class="annexa-field p-l-xs " ng-class="{\'editable-click\' : isUpdate}" ng-click="openWindow(textArea1Form, $data, isUpdate, field.noEditable)" ng-show="!textArea1Form.$visible" editable-textarea="field.valueFromJSON" e-maxlength="900" e-rows="3" e-form="textArea1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.relatedCustomField.templateTag}}">';
            template += '               {{ field.valueFromJSON }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="textArea1Form.$show()" ng-show="!textArea1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs minH-20" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.relatedCustomField.frontendType == \'JSON\'" >';
            template += '           <a href="" class="abs-r-0 abs-b-0" style="min-height: 2.6em;" ng-click="openJSONViewer(field.valueFromJSON, field.relatedCustomField[languageColumn])" ng-show="!json1Form.$visible" role="button" aria-label="{{\'global.literals.open\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-expand grey-pencil" title="{{\'global.literals.open\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.open\' | translate}}</span>';
            template += '           </a>';
            template += '           <a class="annexa-field p-l-xs " ng-class="{\'editable-click\' : isUpdate}" ng-click="openWindow(json1Form, $data, isUpdate, field.noEditable)" ng-show="!json1Form.$visible" editable-textarea="field.valueFromJSON" e-maxlength="9999" e-rows="3" e-form="json1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.relatedCustomField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="json1Form.$show()" ng-show="!json1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs " ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.relatedCustomField.frontendType == \'SELECT\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{ \'editable-click\' : isUpdate }" ng-show="!select1Form.$visible" editable-select="field.valueFromJSON" e-ng-options="item.id as item[languageColumn] for item in field.relatedCustomField.listValues" e-form="select1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.relatedCustomField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }} ';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="select1Form.$show()" ng-show="!select1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil "  title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs " ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.relatedCustomField.frontendType == \'SELECT_LINKED\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{ \'editable-click\' : isUpdate }" ng-show="!select1Form.$visible" editable-select="field.valueFromJSON" e-ng-options="item.id as item[languageColumn] for item in getOptions(field)" e-form="select1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.relatedCustomField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }} ';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="select1Form.$show()" ng-show="!select1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil "  title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.relatedCustomField.frontendType == \'MULTIPLESELECT\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{ \'editable-click\' : isUpdate }" ng-show="!multiselect1Form.$visible" editable-select="field.valueFromJSON" e-multiple e-ng-options="item.id as item[languageColumn] for item in field.relatedCustomField.listValues"  e-form="multiselect1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.relatedCustomField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="multiselect1Form.$show()" ng-show="!multiselect1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil "  title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="radio-list label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.relatedCustomField.frontendType == \'CHECKBOX\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{ \'editable-click\' : isUpdate }" ng-show="!checkBox1Form.$visible" editable-select="field.valueFromJSON" e-multiple e-ng-options="item.id as item[languageColumn] for item in field.relatedCustomField.listValues" e-form="checkBox1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.relatedCustomField.templateTag}}">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="checkBox1Form.$show()" ng-show="!checkBox1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="radio-list label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.relatedCustomField.frontendType == \'RADIO\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{ \'editable-click\' : isUpdate }" ng-show="!radio1Form.$visible"  editable-radiolist="field.valueFromJSON" e-ng-options="item.id as item[languageColumn] for item in field.relatedCustomField.listValues" e-form="radio1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" template-tag="{{field.relatedCustomField.templateTag}}">';
            template += '               {{getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="radio1Form.$show()" ng-show="!radio1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="radio-list label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.relatedCustomField.frontendType == \'STRUCTURAL_SELECT\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{ \'editable-click\' : isUpdate }" ng-show="!tree1Form.$visible"  editable-select-tree="field.valueFromJSON" e-form="tree1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required">';
            template += '               {{getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="tree1Form.$show()" ng-show="!tree1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="radio-list label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.relatedCustomField.frontendType == \'CF_GROUP\'">';
            template +='			<annexa-object-custom-fields-edit-group custom-fields="fields" custom-field="field" data="groupData" class="row m-xs-0"></annexa-object-custom-fields-edit-group>';
            template += '       </p>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        return {
            restrinct: 'E',
            replace: true,
            scope: {
            	fields: '=',
                field: '=',
                groupData: '=',
                isUpdate: '=',
                updateFunction: '=',
                permissions: '=',
                profile: '='
            },
            link: function(scope, element, attr) {
            	scope.languageColumn = Language.getActiveColumn();
                scope.htmlPermissions = '';
                if(scope.permissions && scope.profile && scope.profile.length > 0) {
                    scope.htmlPermissions += ' annexa-permission annexa-permission-only="permissions" annexa-permission-only-profile="profile" ';
                } else if (scope.permissions) {
                	scope.htmlPermissions += ' annexa-permission annexa-permission-only="permissions"';
                } else if (scope.profile && scope.profile.length > 0) {
                	scope.htmlPermissions += ' annexa-permission annexa-permission-only-profile="profile" ';
                }

                if(scope.field && scope.field.value){
                	scope.field.valueFromJSON = scope.field.value;
                	try{
	                	if(scope.field.relatedCustomField.backendType == "DATETIME"){
	                		if(scope.field.valueFromJSON){
	                			scope.field.valueFromJSON = new Date(scope.field.valueFromJSON);
	                		}
	                    }else  if (scope.field.relatedCustomField.frontendType == 'INPUT' && (scope.field.relatedCustomField.backendType == 'INTEGER')) {
	                    	scope.field.valueFromJSON = parseInt(scope.field.valueFromJSON);
	                    } else if (scope.field.relatedCustomField.frontendType == 'INPUT' && scope.field.relatedCustomField.backendType == 'DECIMAL') {
                            var valueLiteral_parts = (scope.field.valueFromJSON+'').split('.');
                            if(valueLiteral_parts && valueLiteral_parts.length > 0){
                                if(valueLiteral_parts[1]){
                                    if(scope.field.relatedCustomField.decimalPositions){
                                    	scope.field.valueFromJSON =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(scope.field.relatedCustomField.decimalPositions));
                                    }else{
                                    	scope.field.valueFromJSON =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
                                    }
                                }else{
                                    if(scope.field.relatedCustomField.decimalPositions){
                                    	scope.field.valueFromJSON =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0.00").toFixed(scope.field.relatedCustomField.decimalPositions));
                                    }else {
                                    	scope.field.valueFromJSON =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
                                    }
                                }
                            }
	                    }  else if(scope.field.relatedCustomField.frontendType == 'INPUT' && scope.field.relatedCustomField.backendType == 'ADDRESS') {
	                    	scope.field.valueFromJSON = JSON.parse(scope.field.valueFromJSON);
	                    } else if(scope.field.relatedCustomField.frontendType == 'RADIO' || scope.field.relatedCustomField.frontendType == 'SELECT'  || (scope.field.relatedCustomField.frontendType == 'SELECT_LINKED' && scope.groupData.extra && scope.groupData.extra.seeSelectedLinked === true)){
	                        if(scope.field.relatedCustomField.backendType == 'INTEGER'){
	                        	scope.field.valueFromJSON= parseInt(scope.field.valueFromJSON);
	                        }
	                    }else if(scope.field.relatedCustomField.frontendType == 'CHECKBOX' || scope.field.relatedCustomField.frontendType == 'MULTIPLESELECT'){
	                        var vals = [];
	                        if(scope.field.relatedCustomField.listValues){
	                            var modelsSelected = scope.field.valueFromJSON.split(',');
	                            _.forEach(modelsSelected, function(option){
	                                if(scope.field.relatedCustomField.backendType == 'INTEGER' && option){
	                                	vals.push(parseInt(option));
	                                }else{
	                                	vals.push(option);
	                                } 
	                            });
	                        }
	                        scope.field.valueFromJSON = vals;
	                    }else if(scope.field.relatedCustomField.frontendType == 'STRUCTURAL_SELECT'){
	                    	var selected = undefined;
	                    	var options = angular.copy(scope.field.relatedCustomField.listValues); 
	                    	_.forEach(options, function(val){
	                    		if(val.parentValue && val.parentValue.id){
	                    			val.parent = {id:val.parentValue.id};
	                    		}
	                    	});
	                    	options = CommonService.getTreeData(options, Language.getActiveColumn());
	                        var found = false;
	                        if(options){
	                            angular.forEach(options, function (val, key) {
	                                 if (!found) {
	                                     selected = $linq(val).singleOrDefault(undefined, "x => x.id == " + scope.field.valueFromJSON);
	                                     if (selected) {
	                                         found = true;
	                                     }
	                                 }
	                            });
	                        }
	                        scope.field.valueFromJSON = {
	                			model: ((selected)?{$selected: selected}:{}),
	                            options: options,
	                            required: scope.field.required
	                    	}
	                    }
                	}catch(e){
                		console.error(e);
                		if(scope.field.relatedCustomField.frontendType == 'MULTIPLESELECT' || scope.field.relatedCustomField.frontendType == 'CHECKBOX'){
                        	scope.field.valueFromJSON = [];
                        }else if(scope.field.relatedCustomField.frontendType === 'STRUCTURAL_SELECT'){
                        	var options = angular.copy(scope.field.relatedCustomField.listValues); 
                        	_.forEach(options, function(val){
                        		if(val.parentValue && val.parentValue.id){
                        			val.parent = {id:val.parentValue.id};
                        		}
                        	});
                        	options = CommonService.getTreeData(options, Language.getActiveColumn());
                        	scope.field.valueFromJSON = {
                    			model: {},
                                options: options,
                                required: scope.field.required
                        	}
                        }else{
                        	scope.field.valueFromJSON = '';
                        }
                	}
                }else{
                    if(scope.field.relatedCustomField.frontendType == 'MULTIPLESELECT' || scope.field.relatedCustomField.frontendType == 'CHECKBOX'){
                    	scope.field.valueFromJSON = [];
                    }else if(scope.field.relatedCustomField.frontendType === 'STRUCTURAL_SELECT'){
                    	var options = angular.copy(scope.field.relatedCustomField.listValues); 
                    	_.forEach(options, function(val){
                    		if(val.parentValue && val.parentValue.id){
                    			val.parent = {id:val.parentValue.id};
                    		}
                    	});
                    	options = CommonService.getTreeData(options, Language.getActiveColumn());
                    	scope.field.valueFromJSON = {
                			model: {},
                            options: options,
                            required: scope.field.required
                    	}
                    }else{
                    	scope.field.valueFromJSON = '';
                    }
                }
                if (scope.field && scope.field.relatedCustomField && scope.field.relatedCustomField.backendType == "DECIMAL"){
                    if(scope.field.relatedCustomField.decimalPositions) {
                    	scope.field.step = '0.' + Array(scope.field.relatedCustomField.decimalPositions).join('0') + '1';
                    }else{
                    	scope.field.step = '0.01'
                    }
                }
                if(scope.field && scope.field.relatedCustomField && scope.field.relatedCustomField.frontendType === 'SELECT'){
                	if(!scope.field.required){
                		if(!$linq(scope.field.relatedCustomField.listValues).firstOrDefault(undefined, "x => x.id == undefined")){
                			scope.field.relatedCustomField.listValues.unshift({id:undefined, language1:$filter('translate')('global.literals.notAssign'), language2:$filter('translate')('global.literals.notAssign'), language3:$filter('translate')('global.literals.notAssign')});
                		}
                	}
                }
                scope.descriptionLanguageColumn = "descriptionLanguage" + Language.getActiveColumn().substr(Language.getActiveColumn().length - 1);
                scope.getCustomFieldValue = function(field){
                    var valueLiteral = '';
                    if(field.relatedCustomField.frontendType == 'MULTIPLESELECT' || field.relatedCustomField.frontendType == 'CHECKBOX'){
                        angular.forEach(field.valueFromJSON,function (valueJ, keyJ) {
                            angular.forEach(field.relatedCustomField.listValues, function (value, key) {
                                if (value.id == valueJ) {
                                    if(valueLiteral) {
                                        valueLiteral = valueLiteral +', '+ value[scope.languageColumn];
                                    }else{
                                        valueLiteral = value[scope.languageColumn];
                                    }
                                }
                            });
                        });
                    }else if(field.relatedCustomField.frontendType == 'SELECT' || field.relatedCustomField.frontendType == 'SELECT_LINKED' || field.relatedCustomField.frontendType == 'RADIO'){
                        angular.forEach(field.relatedCustomField.listValues, function (value, key) {
                            if (value.id == field.valueFromJSON) {
                                valueLiteral = value[scope.languageColumn];
                            }
                        });
                    }else if(field.relatedCustomField.frontendType == 'INPUT'){
                        if(field.relatedCustomField.backendType == "DATETIME") {
                            if(field.valueFromJSON){
                            	valueLiteral = $filter('date')(field.valueFromJSON, 'dd/MM/yyyy');
                            }
                        }else if(field.relatedCustomField.backendType == "DECIMAL"){
                            if(field.valueFromJSON){
                                valueLiteral = field.valueFromJSON;
                                var valueLiteral_parts = (""+valueLiteral).split('.');
                                if(valueLiteral_parts && valueLiteral_parts.length > 0){
                                    if(valueLiteral_parts[1]){
                                        if(field.relatedCustomField && field.relatedCustomField.decimalPositions){
                                            valueLiteral =  parseFloat(valueLiteral_parts[0]).toLocaleString() + parseFloat("0."+valueLiteral_parts[1]).toFixed(field.relatedCustomField.decimalPositions).replace('0.',',');
                                        }else{
                                            valueLiteral =  parseFloat(valueLiteral_parts[0]).toLocaleString() + parseFloat("0."+valueLiteral_parts[1]).toFixed(2).replace('0.',',');
                                        }
                                    }else{
                                        if(field.relatedCustomField && field.relatedCustomField.decimalPositions){
                                            valueLiteral =  parseFloat(valueLiteral_parts[0]).toLocaleString() +  parseFloat("0.00").toFixed(field.relatedCustomField.decimalPositions).replace('0.',',');
                                        }else {
                                            valueLiteral =  parseFloat(valueLiteral_parts[0]).toLocaleString() + ",00";
                                        }
                                    }
                                }
                            }else{
                                valueLiteral = field.valueFromJSON;
                            }
                        }else if(field.relatedCustomField.backendType == "INTEGER"){
                            if(field.valueFromJSON){
                                valueLiteral = field.valueFromJSON.toLocaleString();
                            }else{
                                valueLiteral = field.valueFromJSON;
                            }
                        } else if (field.relatedCustomField.backendType == "ADDRESS"){
                        	if (field.valueFromJSON) {
                                valueLiteral = AnnexaEntityFactory.postalAddressRender(field.valueFromJSON);
                            } else {
                                valueLiteral = field.valueFromJSON;
                            }
                        }else{
                            valueLiteral = field.valueFromJSON;
                        }
                    }else if(field.relatedCustomField.frontendType == 'JSON' || field.relatedCustomField.frontendType == 'CF_GROUP'){
                    	var maxLengthReadModeCustomFieldJson = 100;
                    	if ($rootScope.app.configuration.max_length_read_mode_custom_field_json && $rootScope.app.configuration.max_length_read_mode_custom_field_json.value > 0) {
                    		maxLengthReadModeCustomFieldJson = $rootScope.app.configuration.max_length_read_mode_custom_field_json.value;
                        }
                        if(field.valueFromJSON && field.valueFromJSON.length > maxLengthReadModeCustomFieldJson){
                            valueLiteral = field.valueFromJSON.substr(0,maxLengthReadModeCustomFieldJson) + '...';
                        }else{
                            valueLiteral = field.valueFromJSON;
                        }
                    }else if(field.relatedCustomField.frontendType == 'STRUCTURAL_SELECT'){
                    	if(field && field.relatedCustomField && field.relatedCustomField.listValues){
	                    	angular.forEach(field.relatedCustomField.listValues, function (value, key) {
	                            if (field.valueFromJSON && field.valueFromJSON.model && field.valueFromJSON.model.$selected && field.valueFromJSON.model.$selected.id && field.valueFromJSON.model && value.id == field.valueFromJSON.model.$selected.id) {
	                                valueLiteral = value[scope.languageColumn];
	                            }
	                        });
                    	}
                    }
                    return valueLiteral;
                };

                scope.updateCustomField = function(field, data, isLinked){
                    if(!field.required || (field.required && data)) {
                        var value = undefined;
                        if(data && data instanceof Date) {
                            value = new Date(Date.UTC(data.getFullYear(),data.getMonth(),data.getDate(),00,00,00));
                        }else if(field && field.relatedCustomField && field.relatedCustomField.frontendType  === 'STRUCTURAL_SELECT'){
                        	if(data && data.model && data.model.$selected && data.model.$selected.id){
                        		value = data.model.$selected.id;
                        	}else{
                        		value = undefined;
                        	}
                        }else if(field && field.relatedCustomField && field.relatedCustomField.backendType  === 'ADDRESS'){
                        	if(data){
                        		value = JSON.stringify(data);
                        	}else{
                        		value = undefined;
                        	}
                        } else if(field && field.relatedCustomField && field.relatedCustomField.frontendType === 'MULTIPLESELECT' || field.relatedCustomField.frontendType === 'CHECKBOX'){
                        	if(data && Array.isArray(data)){
                        		value = "";
                        		_.forEach(data, function(itm){
                        			value = value + ((value === "")?"":",") + itm;
                        		});
                        	}else{
                        		value = data;
                        	}
                        	
                        }else {
                            value = data;
                        }
                        if (value && (field.relatedCustomField.frontendType == 'JSON' || field.relatedCustomField.frontendType == 'CF_GROUP')) {
                        	try {
                        		JSON.parse(value);
                            } catch (error) {
                    			return $filter('translate')("global.validation.json");
                    		}
                        }
                        field.value = value;
                        if(!isLinked && field.relatedCustomFields && field.relatedCustomFields.length > 0){
                        	field.valuesRelatedCustomFields = {};
                        	field.jsonValuesRelatedCustomFields = {};
                        	var okRelated = true;
                        	var lvalue = undefined;
                        	if(field && field.relatedCustomField && (field.relatedCustomField.backendType === 'MULTIPLESELECT' || field.relatedCustomField.backendType === 'CHECKBOX')){
                        		if(value && value.length != 1){
                        			okRelated = false;
                        		}
                        		if(okRelated){
                        			if(field.relatedCustomField && field.relatedCustomField.listValues){
                        				lvalue = $linq(field.relatedCustomField.listValues).firstOrDefault(undefined, "x=> x.id == "+value[0]);
                        			}
                        		}
                        	}else{
                        		if(okRelated){
                        			if(field.relatedCustomField && field.relatedCustomField.listValues){
                        				lvalue = $linq(field.relatedCustomField.listValues).firstOrDefault(undefined, "x=> x.id == "+value);
                        			}
                        		}
                        	}
//                        	if(lvalue){
//                        		_.forEach(field.relatedCustomFields, function(rc){
//                        			if(rc.customFieldTemplateTag){
//                        				var cffieldUpdated = $linq(scope.fields).firstOrDefault(undefined, "x => x.customField && x.customField.templateTag == '"+rc.customFieldTemplateTag+"'");
//                        				if(cffieldUpdated){
//                        					if(lvalue[rc.customFieldTemplateTag]){
//                        						var relatedValue = undefined;
//                        						try{
//                        							if(cffieldUpdated.customField.backendType === 'DATETIME'){
//                        								try{
//                        									var dateParts = lvalue[cffieldUpdated.customField.templateTag].split("/");
//                        									var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
//                        									if(dateObject != "Invalid Date") {
//                        										relatedValue = dateObject;
//                        									} else {
//                        										dateParts = HelperService.dateMaskToDate(dateParts);
//                        										relatedValue = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
//                        									}
//                        								}catch(e){
//                        									var dateTime = Date.parse(lvalue[cffieldUpdated.customField.templateTag]);
//                        									if(dateTime){
//                        										relatedValue = new Date(dateTime);
//                        									}else{
//                        										relatedValue = undefined;
//                        									}
//                        								}
//                        							}else if(cffieldUpdated.customField.frontendType == 'INPUT' && cffieldUpdated.customField.backendType === 'INTEGER'){
//                        								relatedValue = parseInt(lvalue[cffieldUpdated.customField.templateTag]);
//                        							}else if(cffieldUpdated.customField.frontendType == 'INPUT' && cffieldUpdated.customField.backendType === 'DECIMAL'){
//                        								var valueLiteral_parts = (lvalue[cffieldUpdated.customField.templateTag]).split('.');
//                        								if(valueLiteral_parts && valueLiteral_parts.length > 0){
//                        									if(valueLiteral_parts[1]){
//                        										if(cffieldUpdated.customField.decimalPositions){
//                        											relatedValuee =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(cffieldUpdated.customField.decimalPositions));
//                        										}else{
//                        											relatedValue =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0."+valueLiteral_parts[1]).toFixed(2));
//                        										}
//                        									}else{
//                        										if(cffieldUpdated.customField.decimalPositions){
//                        											relatedValue =  parseFloat(valueLiteral_parts[0]) + parseFloat(parseFloat("0.00").toFixed(cffieldUpdated.customField.decimalPositions));
//                        										}else {
//                        											relatedValue =  parseFloat(parseFloat(valueLiteral_parts[0]).toLocaleString() + ".00");
//                        										}
//                        									}
//                        								}
//                        							}else if(cffieldUpdated.customField.frontendType == 'INPUT' && cffieldUpdated.customField.backendType === 'ADDRESS'){
//                        								//TODO no pot arribar un address des de BBDD
//                        							}else if((cffieldUpdated.customField.frontendType === 'SELECT' || cffieldUpdated.customField.frontendType === 'SELECT_LINKED'  || cffieldUpdated.customField.frontendType === 'RADIO')){
//                        								relatedValue = parseInt(lvalue[cffieldUpdated.customField.templateTag]);
//                        							} else if(cffieldUpdated.customField.frontendType === 'MULTIPLESELECT' || cffieldUpdated.customField.frontendType === 'CHECKBOX'){
//                        								relatedValue = [parseInt(lvalue[cffieldUpdated.customField.templateTag])];
//                        							} else if(cffieldUpdated.customField.frontendType === 'STRUCTURAL_SELECT'){
//                        								var aux = parseInt(lvalue[cffieldUpdated.customField.templateTag]);
//                        								if(aux){
//                        									var lvaux = $linq(cffieldUpdated.customField.listValues).firstOrDefault(undefined, "x => x.id == aux");
//                        									if(lvaux){
//                        										relatedValue = lvaux.id;											
//                        									}
//                        								}
//                        							}else{
//                        								relatedValue = lvalue[cffieldUpdated.customField.templateTag];
//                        							}
//                        						}catch(e){
//                        							if(cffieldUpdated && cffieldUpdated.customField && (cffieldUpdated.customField.backendType === 'MULTIPLESELECT' || cffieldUpdated.customField.backendType === 'CHECKBOX')){
//                        								relatedValue = [];
//                        							}else{
//                        								relatedValue = undefined;
//                        							}
//                        						}
//                        					}else{
//                        						if(cffieldUpdated && cffieldUpdated.customField && (cffieldUpdated.customField.backendType === 'MULTIPLESELECT' || cffieldUpdated.customField.backendType === 'CHECKBOX')){
//                        							relatedValue = [];
//                        						}else{
//                        							relatedValue = undefined;
//                        						}
//                        					}
//                        					field.valuesRelatedCustomFields[cffieldUpdated.customField.templateTag] = relatedValue;
//                        					field.jsonValuesRelatedCustomFields[cffieldUpdated.customField.templateTag] = relatedValue;
//                        				}
//                        			}
//                        		});
//                        	}
                        }
                        if(scope.updateFunction){
                            scope.updateFunction(field);
                        }else{
							if(field.relatedCustomField && field.relatedCustomField.backendType == 'ADDRESS'){
								if(field.value){
									field.valueFromJSON = field.value;
								}else {
									field.valueFromJSON = '';
								}
							}else{
								field.valueFromJSON = '';
							}
						}
                        if(!scope.updateFunction){
	                        if(field.relatedCustomField.frontendType == 'SELECT') {
	                            scope.$broadcast('customFieldSelectSelectedGroup', { customField: field.relatedCustomField.id, selectedValue: field.id });
	                        }
                        }
                    }else{
                        return 'required';
                    }
                };

                scope.getOptions = function(field) {
                    if(field.relatedCustomField.frontendType == 'SELECT_LINKED'){
                    	var options = [];
                    	if(field.relatedCustomField.fromQuery && field.relatedCustomField.query && field.relatedCustomField.query.id) {
                    		options = field.relatedCustomField.listValues;
                    	}else{
                    		var getParentsFieldsGroup = function(fields, field){
                    			var parents = [];
                    			if(field && fields){
                    				_.forEach(fields, function(f){
                    					var existField = false;
                    					if(f.groups != null && f.groups.length > 0){
                    						existField = $linq(f.groups).firstOrDefault(undefined, "x => x.id == " + field.id);
                    						if(existField){
                    							parents.push(f);
                    						}
                    					}
                    					if(!existField && f.groups != null && f.groups.length > 0){
                							var parentsAux = getParentsFieldsGroup(f.groups, field);
                							if(parentsAux && parentsAux.length > 0){
                								_.forEach(parentsAux, function(pa){
                									parents.push(pa);
                								});
                							}
                    					}
                    				});
                    			}
                    			return parents;
                    		}
                    		var parentField = undefined;
                    		var existField = $linq(scope.fields).firstOrDefault(undefined, "x => x.id == " + field.id);
                    		if(existField){
                    			parentField = $linq(scope.fields).firstOrDefault(undefined, "x => x.customField.id == " + field.relatedCustomField.linkedCustomField.id);
                    		}else{
                    			var parents = getParentsFieldsGroup(scope.fields, field);
                    			if(parents && parents.length > 0){
                    				_.forEach(parents, function(p){
                    					if(!parentField){
	                    					var existField = $linq(p.groups).firstOrDefault(undefined, "x => x.relatedCustomField.id == " + field.relatedCustomField.linkedCustomField.id);
	                						if(existField){
	                							parentField = existField;
	                						}
                    					}
                    				});
                    			}
                    		}
	                        if(parentField) {
	                        	if(parentField.relatedCustomField && parentField.relatedCustomField.id){
	                        		var parentValue = $linq(parentField.relatedCustomField.listValues).singleOrDefault(undefined, "x => x.id == " + parentField.valueFromJSON);
	                        		if(parentValue) {
	                        			options = $linq(field.relatedCustomField.listValues).where("x => x.parentValue.id == " + parentValue.id).toArray();
	                        		}
	                        	}else{
	                        		var parentValue = $linq(parentField.customField.listValues).singleOrDefault(undefined, "x => x.id == " + parentField.valueFromJSON);
	                        		if(parentValue) {
	                        			options = $linq(field.customField.listValues).where("x => x.parentValue.id == " + parentValue.id).toArray();
	                        		}
	                        	}
	                        }
                    	}
                        return options;
                    }
                }

                scope.$on('customFieldSelectSelectedGroup', function (event, args) {
                	if(args.customField && args.selectedValue && scope.field && scope.field.id == args.selectedValue) {
                		var parent = undefined;
                		var getParent = function(parents, index, fields){
                			var prn = undefined;
                			if(parents && parents.length > index){
                				var p = $linq(fields).firstOrDefault(undefined, "x => x.id == "+parents[index]);
                				if(p && parents.length === index+1){
               						prn = p;
                				}else if(p){
                					prn = getParent(parents, index+1, p.groups);
                				}
                			}
                			return prn;
                		}
                		parent = getParent(scope.field.parents, 0, scope.fields);
                		var calculatedLinkeds = function(gr){
            				var ls = [];
            				if(gr && gr.groups){
            					var actual = $linq(gr.groups).firstOrDefault(undefined,"x => x.relatedCustomField && x.relatedCustomField.id == "+scope.field.relatedCustomField.id);
            					if(!actual){
            						var linkedCFs = $linq(gr.groups).where("x => x.relatedCustomField && x.relatedCustomField.frontendType == 'SELECT_LINKED' && x.relatedCustomField.linkedCustomField && x.relatedCustomField.linkedCustomField.id == "+scope.field.relatedCustomField.id).toArray();
            						_.forEach(gr.groups,function(g){
            							if(g.relatedCustomField && g.relatedCustomField.frontendType === 'CF_GROUP'){
            								var lss = calculatedLinkeds(g);
            								if(lss && lss.length > 0){
            									_.forEach(lss, function(l){
            										linkedCFs.push(l);
            									});
            								}
            							}
            						});	
            						if(linkedCFs && linkedCFs.length > 0){
    									_.forEach(linkedCFs, function(l){
    										ls.push(l);
    									});
    								}
            					}
        							
            				}
            				return ls;
            			}
            			if(parent && parent.groups){
            				var linkedCFs = $linq(parent.groups).where("x => x.relatedCustomField && x.relatedCustomField.frontendType == 'SELECT_LINKED' && x.relatedCustomField.linkedCustomField && x.relatedCustomField.linkedCustomField.id == "+scope.field.relatedCustomField.id).toArray();
    						_.forEach(parent.groups,function(g){
    							if(g.relatedCustomField && g.relatedCustomField.frontendType === 'CF_GROUP'){
    								var ls = calculatedLinkeds(g);
    								if(ls && ls.length > 0){
    									_.forEach(ls, function(l){
    										linkedCFs.push(l);
    									});
    								}
    							}
    						});
            			}
                        _.forEach(linkedCFs, function(field) {
                        	scope.updateCustomField(field, '', true);
                        	field.valueFromJSON = '';
                        	if(field && field.relatedCustomField.fromQuery && field.relatedCustomField.query && field.relatedCustomField.query.id){
                        		if(args.customField && args.selectedValue && scope.groupData && scope.groupData.extra && scope.groupData.extra.valuesFromLinkedCustomFields && scope.groupData.extra.valuesFromLinkedCustomFields.parentId && scope.groupData.extra.valuesFromLinkedCustomFields.objectParent && field.identifierField && field.labelPropField && args.selectedValue) {
                        			var url = "";
                        			if(scope.groupData.extra.valuesFromLinkedCustomFields.objectParent === "DOSSIER"){
                        				url = './api/tram/new/dossier/calculateGroupCustomFields/'+scope.groupData.extra.valuesFromLinkedCustomFields.parentId+'/'+((field.id == undefined)?-1:field.id)+'/'+field.relatedCustomField.id+'/'+scope.field.value+'/'+args.customField;
                        			}else if(scope.groupData.extra.valuesFromLinkedCustomFields.objectParent === "DOSSIER_TRANSACTION"){
                        				url = './api/tram/new/dossierTransaction/calculateGroupCustomFields/'+scope.groupData.extra.valuesFromLinkedCustomFields.parentId+'/'+((field.id == undefined)?-1:field.id)+'/'+field.relatedCustomField.id+'/'+scope.field.value+'/'+args.customField;
                        			}else if(scope.groupData.extra.valuesFromLinkedCustomFields.objectParent === "TASK"){
                        				url = './api/tasks/calculateGroupCustomFields/'+scope.groupData.extra.valuesFromLinkedCustomFields.parentId+'/'+((field.id == undefined)?-1:field.id)+'/'+field.relatedCustomField.id+'/'+scope.field.value+'/'+args.customField;
                        			}else if(scope.groupData.extra.valuesFromLinkedCustomFields.objectParent === "DOCUMENT"){
                        				url = './api/doc/calculateGroupCustomFields/'+scope.groupData.extra.valuesFromLinkedCustomFields.parentId+'/'+((field.id == undefined)?-1:field.id)+'/'+field.relatedCustomField.id+'/'+scope.field.value+'/'+args.customField;
                        			}
                        			if(url && url !== ""){
                        				$http({method: 'GET',url: url}).then(function(data){
	                            		  if(data){
	                            			  var opts = ((data && data.data && data.data.length > 0)?JSOG.decode(data.data):[]);
	                            			  var newopts = [];
	                            			  _.forEach(opts, function(lv){
	                            					if(lv[field.identifierField]){
	                            						var label = '';
	                            						var labelPropFieldS = field.labelPropField.split("--@--@--");
	                            						_.forEach(labelPropFieldS, function(lp){
	                            							label = label + ((label)?((field.labelFieldsSeparator)?field.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
	                            						});
	                            						newopts.push({id: lv[field.identifierField], language1:label, language2:label, language3:label, value:lv[field.identifierField] });
	                            					}
	                            				});
	                            		  }
	                            		  field.relatedCustomField.listValues = newopts;
                        				}).catch(function(error){
                        					field.relatedCustomField.listValues = [];
                        				});
                        				
                        			 } else {
                                         field.relatedCustomField.listValues = [];
                                     }
                                } else {
                                	field.relatedCustomField.listValues = [];
                                }
                        	}
                        });
                    }
                });
                
                scope.openJSONViewer = function(modelValue, name) {
        			var modal = angular.copy(CommonAdminModals.jsonViewer);
        			modal.data = modelValue;
        			modal.title = name;
	                AnnexaModalFactory.showModal('modalJsonViewer', modal);
                }
                
                scope.openWindow = function(element, modelValue, isUpdate, noEditable){
                	if(scope.canOpenWindow(modelValue)){
                		var modelValueString = ((modelValue && scope.languageColumn && modelValue[scope.languageColumn])?modelValue[scope.languageColumn]:modelValue);
                		if(modelValueString){
                			window.open(modelValueString);
                		}else if(isUpdate && !noEditable && !element.$visible){
                			element.$show();
                		}
                	}else if(isUpdate && !noEditable && !element.$visible){
                		element.$show();
                	}
                }
                
                scope.canOpenWindow = function(modelValue){
            		var exist = false;
                	if(modelValue){
                		var startUrls = ['http://','https://'];
                		if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.url_editable_inputs && $rootScope.app.configuration.url_editable_inputs.urls && $rootScope.app.configuration.url_editable_inputs.urls.length > 0){
                			_.forEach($rootScope.app.configuration.url_editable_inputs.urls, function(item){
                				startUrls.push(item);
                			});
                		}
                		var modelValueString = ((modelValue && scope.languageColumn && modelValue[scope.languageColumn])?modelValue[scope.languageColumn]:modelValue);
                		if(modelValueString){
	                		_.forEach(startUrls, function(item){
	                			if(modelValueString.startsWith(item.toLowerCase())){
	                				exist = true;
	                			}
	                		});
                		}
                	}
                	return exist;
                }

				scope.openModalAddress = function (field){
                	var modal = angular.copy(globalModals.editAddressCF);
                	
                	modal.field = field;
                	modal.updateField = scope.updateCustomField;
                	
                	var defaultCountry = $rootScope.app.configuration.default_country;
                	if(defaultCountry && defaultCountry.value){
                        defaultCountry = defaultCountry.value;
                    }else{
                        defaultCountry = '';
                    }
                	
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {};
                    if(field.valueFromJSON && field.valueFromJSON !== ""){
                    	modal.annexaFormly.model.modal_body = field.valueFromJSON;
                    }
                    if(!modal.annexaFormly.model.modal_body.country && defaultCountry){
                        modal.annexaFormly.model.modal_body.country = {id:defaultCountry};
                    }
                    
                    modal.annexaFormly.model.modal_body.hasStates = true;
                    modal.annexaFormly.model.modal_body.hasCities = true;
                    modal.annexaFormly.model.modal_body.hasStreetTypes = true;
                    
                    modal.extra = this;
                    
                    
                    var submitFunction = function() { 
                    	var self = this;
                    	
                    	var address = {};
                        var addressAux = this.annexaFormly.model.modal_body;

                        address.country = addressAux.country;
                        if(addressAux.hasStates) {
                            if (addressAux.validState && addressAux.validState.id) {
                            	address.validState = addressAux.validState;
                            }
                        }else{
                        	address.stateAddress = addressAux.stateAddress;
                        }
                        if(addressAux.hasCities){
                            if(addressAux.validCity && addressAux.validCity.id) {
                            	address.validCity = addressAux.validCity;
                            }
                        }else{
                        	address.city = addressAux.city;
                        }
                        if(addressAux.hasStreetTypes && addressAux.streetType) {
                        	address.streetType = addressAux.streetType;
                        }
                        
                        address.street = addressAux.street;
                        address.isValidNumber = false;
                        address.numberAddres = addressAux.numberAddres;
                        address.floor = addressAux.floor;
                        address.block = addressAux.block;
                        address.door = addressAux.door;
                        address.stair = addressAux.stair;
                        address.postalCode = addressAux.postalCode;
                        
                        this.updateField(this.field, address);
                    	
                    	self.close();
                    }
                    
                    AnnexaFormlyFactory.showModal("modalNewAddress", modal, submitFunction, false);
                };
                element.html(getTemplate(scope.customFieldsDefinition, scope.htmlPermissions)).show();
                $compile(element.contents())(scope);
            }
        }
    }]);