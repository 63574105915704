/**
 * Created by osirvent on 07/03/2016.
 */
angular
    .module('annexaApp')
    .service('LoginService',['$rootScope', '$http', '$q', '$localStorage', '$state', '$injector', '$window', 'HeaderService', 'Language', '$translate', 'tmhDynamicLocale', 'PermPermissionStore', 'AnnexaPermissionsFactory', 'RestService', 'AnnexaPendingCounterFactory', 'GlobalDataFactory', 'globalModals', 'AnnexaFormlyFactory', '$filter', 'DialogsFactory', '$cookies', function($rootScope, $http, $q, $localStorage, $state, $injector, $window, HeaderService, Language, $translate, tmhDynamicLocale, PermPermissionStore, AnnexaPermissionsFactory, RestService, AnnexaPendingCounterFactory, GlobalDataFactory, globalModals, AnnexaFormlyFactory, $filter, DialogsFactory, $cookies) {
         this.PostLoginActions = function(user, user_roles, user_token, $scope, user_tenant, all_modules, managed_organs, addAutomaticActionPermissions, annexaVersion, activeMultipleRegisterOffice, registerEntryOffices, expiredPassword, notCallUpdateState, rolesSuperAdmin) {
            
            //region DADES USUARI
            
            var loggedUser = user;
            loggedUser.isAuthenticated = true;
            loggedUser.roles = user_roles;
            loggedUser.token = user_token;
            loggedUser.tenant = user_tenant;
        	loggedUser.apiUrl = $rootScope.app.baseUrl + 'api/users/' + loggedUser.id;
            loggedUser.managedOrgans = managed_organs;

             //Carregar actions permissions de l'usuari
             AnnexaPermissionsFactory.enableSecurity(loggedUser, addAutomaticActionPermissions, activeMultipleRegisterOffice, registerEntryOffices, rolesSuperAdmin);

            //DADES GENERALS
            $http.defaults.headers.common['x-auth-token'] = user_token;
            $http.defaults.headers.common['x-tenant-ID'] = user_tenant;
            
            if (!loggedUser.photo) {
                loggedUser.photo = 'general-user.png';
            }
            $rootScope.LoggedUser = user;
            $rootScope.userLoaded = true;
            $rootScope.app.annexaVersion = annexaVersion;
            //endregion
            
            //region DADES $localstorage
            
            $localStorage.LoggedUserID = loggedUser.id;
            $localStorage.LoggedUserToken = loggedUser.token;
            $localStorage.LoggedUserTenant = loggedUser.tenant;
            
            //endregion

            //region DADES APP
            if(expiredPassword && expiredPassword == true){
            	var lservice = this;
            	var changePasswordSubmit = function() {
                    if(this.annexaFormly.form.$valid) {
                        var modal = this;
                        var model = modal.annexaFormly.model.modal_body;

                        $http({
                            url: './api/users/check_password/' + loggedUser.id,
                            method: 'GET',
                            params:{
                                password:model.oldPassword
                            }
                        }).then(function(data) {
                            if(data.data) {
                                if(model.newPassword != model.rePassword) {
                                	modal.alerts.push({ msg: 'global.errors.changePasswordError' });
                                } else {
                                    $http({
                                        url: './api/users/change_password/' + loggedUser.id,
                                        method: 'PUT',
                                        data: {
                                        	password: model.newPassword,
                                        	oldPassword: model.oldPassword
                                        }
                                    }).then(function (data) {
                                        modal.close();
                                        lservice.LogOut($scope);
                                    }).catch(function (error) {
                                    	if(error && error.data && error.data.message == "Password invalid format exception." && GlobalDataFactory.passwordErrorMessage){
                                    		modal.alerts.push({ msg: GlobalDataFactory.passwordErrorMessage });
                                    	}else{
                                    		modal.alerts.push({ msg: 'global.errors.changePasswordError' });
                                    	}
                                    })
                                }
                            } else {
                            	modal.alerts.push({ msg: 'global.errors.changePasswordError' });
                            }
                        }).catch(function(error) {
                        	modal.alerts.push({ msg: 'global.errors.changePasswordError' });
                        })
                    }
            	}
            	
            	GlobalDataFactory.passwordMessages().then(function (error) {
            		 var modal = globalModals.changePassword;
                     modal.annexaFormly.model = {};
                     modal.annexaFormly.model.modal_body = {};
                     modal.annexaFormly.options = {};
                     modal.annexaFormly.options.formState = { readOnly: false };
                     modal.alerts = [];
                     AnnexaFormlyFactory.showModal('modalChangePassword', modal, changePasswordSubmit, false);            		
            	}).catch(function (error) {
            		$state.go('access.signin');
            	});
            }else{
	            $rootScope.app.loggeduser = loggedUser;
	            $rootScope.allModules = all_modules;
	
	            $rootScope.app.entity = new Entity('id', $rootScope.app.loggeduser.entity.id, $rootScope.app.loggeduser.entity.logo, $rootScope.app.loggeduser.entity[Language.getActiveColumn()], $rootScope.app.loggeduser.entity.holidays);
	
	            $rootScope.esetMode = false;
	            $rootScope.app.UIButtons = [];
	
	
	             if(loggedUser.language && loggedUser.language.acronym){
//	                 $translate.preferredLanguage(loggedUser.language.acronym);
//	                 $translate.use(loggedUser.language.acronym).then(function(){
//	                     $translate.refresh();
//	                 });
//	                 tmhDynamicLocale.set(loggedUser.language.acronym);
	                 $rootScope.app.language = loggedUser.language.acronym;
	                 Language.setCurrent(loggedUser.language.acronym);
	                 $rootScope.app.langColumn = Language.getActiveColumn();
	             }
	             
	             //endregion
	             //region promises
	             if(!notCallUpdateState){ 
	                 if ($rootScope.desiredtoState === undefined) {
	                     $state.go('annexa.dashboard');
	                 } else {
	                     if($rootScope.desiredtoStateParams === undefined) {
	                         $state.go($rootScope.desiredtoState);
	                     } else {
	                         $state.go($rootScope.desiredtoState, $rootScope.desiredtoStateParams);
	                     }
	                 }
                 }	   
	             
	             if($scope) {
                     $injector.get('LoginService').UpdateLoggedUserCounters($scope); //??????????????
                 }
	             //endregion
            }
        };

        this.UpdateLoggedUserCounters = function($scope) {
            if($rootScope) {
                AnnexaPermissionsFactory.getMenuWithPermissions()
                    .then(function (data) {
                        if($rootScope.app.menu) {
                            if(Array.isArray($rootScope.app.menu)){
                                $rootScope.app.menu.length = 0;
                            }else{
                                $rootScope.app.menu = [];
                            }
                            if(data){
                                _.forEach(data, function(value){
                                    $rootScope.app.menu.push(value);
                                });
                            }
                        }else{
                            if(data){
                                $rootScope.app.menu = data;
                            }else{
                                $rootScope.app.menu = [];
                            }
                        }
                        $rootScope.$broadcast('printAnnexaMenu', {menu:$rootScope.app.menu});
                    })
            }
        }

        this.LogOut = function($scope) {
            if($rootScope.LoggedUser) {
                $http({
                    url: '/access/logout',
                    method: 'GET'
                }).then(function(data) {
                	if ($rootScope) {
                        $rootScope.app.loggeduser = undefined;
                    }
                    $localStorage.LoggedUserID = undefined;
                    $localStorage.LoggedUserToken = undefined;
                    $localStorage.LoggedUserTenant = undefined;
                    $rootScope.LoggedUser = undefined;
                    $rootScope.userLoaded = false;
                    $rootScope.desiredtoState = undefined;
                    PermPermissionStore.clearStore();
                    if($http && $http.defaults && $http.defaults.headers && $http.defaults.headers.common){
                    	$http.defaults.headers.common['x-auth-token'] = undefined;
                    	$http.defaults.headers.common['x-tenant-ID'] = undefined;
                    }
                    try{
                    	$cookies.remove('JSESSIONID');
                    }catch(e){}
                    if(data && data.data && data.data.urlToDegrade){
                    	$window.location.href = data.data.urlToDegrade;
                	}else{
                		$state.go('access.signin');
                	}
                }).catch(function (error) {
                	if ($rootScope) {
                        $rootScope.app.loggeduser = undefined;
                    }
                    $localStorage.LoggedUserID = undefined;
                    $localStorage.LoggedUserToken = undefined;
                    $localStorage.LoggedUserTenant = undefined;
                    $rootScope.LoggedUser = undefined;
                    $rootScope.userLoaded = false;
                    $rootScope.desiredtoState = undefined;
                    PermPermissionStore.clearStore();
                    if($http && $http.defaults && $http.defaults.headers && $http.defaults.headers.common){
                    	$http.defaults.headers.common['x-auth-token'] = undefined;
                    	$http.defaults.headers.common['x-tenant-ID'] = undefined;
                    }
                    try{
                    	$cookies.remove('JSESSIONID');
                    }catch(e){}
                    $state.go('access.signin');
                });
            }
        };

        this.ChangeLanguage = function(lang) {
            $rootScope.LoggedUser.language = lang;
        };

        this.ChangeDelegation = function(deleg, remove) {
            if($rootScope.LoggedUser.delegatorUsers && deleg){
                var exist = false;
                for(var i=0; i<$rootScope.LoggedUser.delegatorUsers.length; i++){
                    if($rootScope.LoggedUser.delegatorUsers[i].id == deleg.id){
                        if(remove){
                            $rootScope.LoggedUser.delegatorUsers.splice(i, 1);
                            exist = true;
                            break;
                        }else{
                            $rootScope.LoggedUser.delegatorUsers[i].startDelegationDate.time = new Date(deleg.startDelegationDate).getTime();
                            $rootScope.LoggedUser.delegatorUsers[i].startDelegationDate = new Date(deleg.startDelegationDate).getTime();
                            $rootScope.LoggedUser.delegatorUsers[i].endDelegationDate.time =  new Date(deleg.endDelegationDate).getTime();
                            $rootScope.LoggedUser.delegatorUsers[i].endDelegationDate =  new Date(deleg.endDelegationDate).getTime();
                            $rootScope.LoggedUser.delegatorUsers[i].delegatorUser = deleg.delegatorUser;
                            $rootScope.LoggedUser.delegatorUsers[i].delegatedUser = deleg.delegatedUser;
                            $rootScope.LoggedUser.delegatorUsers[i].createdDate.time =  new Date(deleg.createdDate).getTime();
                            $rootScope.LoggedUser.delegatorUsers[i].createdDate =  new Date(deleg.createdDate).getTime();
                            $rootScope.LoggedUser.delegatorUsers[i].delegationUserPositions = deleg.delegationUserPositions;
                            exist = true;
                            break;
                        }
                    }
                }
                if(!exist && !remove){
                    var delegToCache = {
                        id:deleg.id,
                        startDelegationDate:{time: new Date(deleg.startDelegationDate).getTime()},
                        endDelegationDate:{time: new Date(deleg.endDelegationDate).getTime()},
                        delegatorUser:deleg.delegatorUser,
                        delegatedUser:deleg.delegatedUser,
                        delegationUserPositions:deleg.delegationUserPositions,
                        createdDate:{time: new Date(deleg.createdDate).getTime()}
                    }
                    $rootScope.LoggedUser.delegatorUsers.push(delegToCache);
                }

                HeaderService.changeState($state.current);
            }
        };

        this.getLoadUser = function($scope) {
            if($localStorage.LoggedUserID != null && $localStorage.LoggedUserID != undefined){
                $http.get( "./api/users/getUserLogged"+ "?id=" + $localStorage.LoggedUserID+'&action=authenticateAnnexaAngular').success(function (user) {
                    var loggedUser = angular.fromJson(user.user);
                    loggedUser.isAuthenticated = true;
                    loggedUser.roles = user.roles;
                    loggedUser.token = user.token;
                    loggedUser.tenant = user.tenant;
                    loggedUser.apiUrl = $rootScope.app.baseUrl + 'api/users/' + loggedUser.id;
                    if (!loggedUser.photo) {
                        loggedUser.photo = 'general-user.png';
                    }
                    $rootScope.app.loggeduser = loggedUser;
                    $rootScope.app.annexaVersion = user.annexaVersion;
                    $rootScope.LoggedUser = loggedUser;
                    $rootScope.userLoaded = true;
                    $injector.get('LoginService').UpdateLoggedUserCounters($scope);
                    if ($rootScope.desiredtoState === undefined) {
                        $state.go('annexa.dashboard');
                    }
                });
            }
        };

        this.getActiveDelegations = function(future) {
        	var activeDelegations = [];
            if($rootScope.LoggedUser && $rootScope.LoggedUser.delegatorUsers) {
                angular.forEach($rootScope.LoggedUser.delegatorUsers, function(delegation) {
                    var endDate = new Date(delegation.endDelegationDate);
                    var startDate = new Date(delegation.startDelegationDate);
                    if(delegation.startDelegationDate.time){
                        var startDate = new Date(delegation.startDelegationDate.time);
                    }
                    if(delegation.startDelegationDate.time){
                        endDate = new Date(delegation.endDelegationDate.time);
                    }
                    var actualDate = new Date();
                    var todayDate = new Date();
                    todayDate.setHours(0,0,0,0);
                    if (actualDate >= startDate && todayDate <= endDate) {
                    	activeDelegations.push(delegation);
                    }else if(future && todayDate <= endDate){
                    	activeDelegations.push(delegation);
                    }
                });
            }

            return activeDelegations;
        }

        this.getActiveDelegation = function(future) {
            var retDelegation = undefined;
            if($rootScope.LoggedUser && $rootScope.LoggedUser.delegatorUsers) {
                angular.forEach($rootScope.LoggedUser.delegatorUsers, function(delegation) {
                    if(!retDelegation) {
                        var endDate = new Date(delegation.endDelegationDate);
                        var startDate = new Date(delegation.startDelegationDate);
                        if(delegation.startDelegationDate.time){
                            var startDate = new Date(delegation.startDelegationDate.time);
                        }
                        if(delegation.startDelegationDate.time){
                            endDate = new Date(delegation.endDelegationDate.time);
                        }
                        var actualDate = new Date();
                        var todayDate = new Date();
                        todayDate.setHours(0,0,0,0);
                        if (actualDate >= startDate && todayDate <= endDate) {
                            retDelegation = delegation;
                        }else if(future && todayDate <= endDate){
                            retDelegation = delegation;
                        }
                    }
                });
            }

            return retDelegation;
        }

        this.validateToken = function() {
            var self = this;
            return $q.all([
                $http(
                    {
                        method: 'GET',
                        url: './access/validatetoken',
                        params: { token: $localStorage.LoggedUserToken }
                    }
                ).then(function(tokendata) {
                    if(!tokendata.data) {
                        $localStorage.LoggedUserID = undefined;
                        $localStorage.LoggedUserToken = undefined;
                        $localStorage.LoggedUserTenant = undefined;
                        $state.go('access.signin');
                    }
                }).catch(function (data) {
                    $localStorage.LoggedUserID = undefined;
                    $localStorage.LoggedUserToken = undefined;
                    $localStorage.LoggedUserTenant = undefined;
                    $state.go('access.signin');
                }),
                $http(
                    {
                        method: 'GET',
                        url: './api/users/getUserLogged',
                        params: { id: $localStorage.LoggedUserID, action : 'authenticateAnnexaAngular' },
                        headers: { 'x-auth-token' : $localStorage.LoggedUserToken, 'x-tenant-ID': $localStorage.LoggedUserTenant }
                    }
                ).then(function(data) {
                    if(data.status == 200) {
                    	if(CacheFactory.get("globalDataCache")){
                    		try {
                    			CacheFactory.get("globalDataCache").put('LoggedUserData',data.data);
                    		} catch (error) {
                    			console.error(error);
                    		}
                        }
                        var tmp = JSOG.decode(data.data)
                        var LoadedUser = data.data;
                        self.PostLoginActions(
                            JSOG.decode(LoadedUser.userObject),
                            LoadedUser.roles,
                            LoadedUser.token,
                            undefined,
                            LoadedUser.tenant,
                            tmp.modules,
                            tmp.managedOrgans,
                            tmp.addAutomaticActionPermissions,
                            tmp.annexaVersion,
                            tmp.activeMultipleRegisterOffice,
                            registerEntryOffices,
                            undefined,
                            true,
                            tmp.actionPermissions
                        );
                    }
                }).catch(function (data) {
                    $localStorage.LoggedUserID = undefined;
                    $localStorage.LoggedUserToken = undefined;
                    $localStorage.LoggedUserTenant = undefined;
                    $state.go('access.signin');
                }),
                RestService.findAll('RoleActionPermission')
                    .then(function(data) {
                        var permissions = [];
                        _.forEach(JSOG.decode(data.data), function (perm) {
                            permissions.push(perm.actionPermission.keyId);
                        });
                        PermPermissionStore.defineManyPermissions(permissions, function (permissionName, transitionProperties) {
                            return true;
                        })
                    }),
            ]);
        }

    }])
    .factory('LoginFactory',['$resource',function($resource) {
        return $resource(':action', {},
            {
                authenticate: {
                    method: 'POST',
                    url : 'access/authenticate',
                    params: {'action' : 'authenticateAnnexaAngular'}
                }
            }
        );
    }]);